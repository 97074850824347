import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"

import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const GuitarSetup = () => {
  return (
    <PageLayout>
      <SEO
        title="Guitar Setup | Your Custom Build | King Electric Guitars"
        ogDescription="I make sure your custom guitar setup is just right for the type of music you play and the way you play it. Let's build your dream guitar!"
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h1>
              Guitar Setup
            </h1>
            <p>
              Guitar setup is personal to every guitarist and can be unique to each individual player. I work with you to make sure your custom guitar is set up for the type of music you play and the way you play it. Contrary to popular belief, there is no such thing as a "standard guitar setup". When you go to a guitar tech for guitar repair services, you have tell them how you use the instrument. Your tuning, string gauge, bridge type, fretboard radius, and playing style all factor into the guitar's setup. Setup is part of general guitar maintenance. But,  there are some choices we need to make during the construction of your custom guitar. These will ensure the most flexibility for the life of the instrument based on how you plan to use it.
            </p>
            <FormWrapper
              modal
              buttonProps={{
                content: "Reach Out. Let's Talk!",
                size: "tiny",
                icon: "chat",
              }}
            >
            <RequestMoreInfo />
          </FormWrapper>
          </div>
        }
      />
      <div className="section textured">
        <TextBlock
          textPadded
          textLeft={
            <div>
              <h2>Tunings</h2>
              <p>
                Most guitarists play in E Standard tuning. But dropped tunings like E-flat Standard, D Standard, and C Standard are becoming more common. Add to that all the alternate and open tunings. You can't tune a guitar differently and expect it to sound right without adjustments to the setup. This is particularly true as you move up the fretboard. I have to adjust the guitar's intonation for each tuning.
              </p>
            </div>
          }
          textRight={
            <div>
              <img src="/images/guitar-setup-tunings.jpg" alt="Guitar Setup Tunings" />
            </div>
          }
        />
      </div>
      <TextBlock
        textPadded
        textLeft={
          <div>
            <img src="/images/guitar-setup-strings.jpg" alt="Guitar Setup String Gauge" />
          </div>
        }
        textRight={
          <div>
            <h2>String Gauge</h2>
            <p>
              How thick or thin your guitar strings are also affect's your guitar's setup. Keep that in mind when you put new strings on any of your guitars. That's right, changing your guitar's string gauge can cause intonation issues. The thickness of the string will change the intonation. And it may need adjustments to the guitar nut and <a href="/guitar-bridges/">bridge height</a>.
            </p>
            <p>
              There are a lot of reasons guitarists choose a certain gauge of strings. Guitarists will often use thicker gauge strings when using dropped tunings. This gives the lower tuning the same tension feel of lower gauge stings in E Standard. The <a href="/custom-guitar-necks/">scale of the neck</a> can also affect which string gauge you may choose. Many guitarists prefer very light strings. Light strings can provide a more focused mid-range and make pitch bends easier to execute. Whatever your preference, we will take it into consideration for your custom guitar build.
            </p>
          </div>
        }
      />
      <PageWrapper color="grey">
        <TextBlock
          textPadded
          textLeft={
            <div>
              <h2>Guitar Intonation</h2>
              <p>
                I adjust your custom guitar's intonation at the bridge of the guitar. Every type of bridge design is a little different and some will have limitations. For instance, the traditional Telecaster bridge uses three saddles - each shared by two strings. This makes intoning the guitar difficult even in standard tuning. To combat this, you can buy "<a href="https://www.stewmac.com/parts-and-hardware/bridges-and-tailpieces/bridges-and-tailpieces-for-electric-guitar/non-trem-bridge-parts/gotoh-in-tune-compensated-saddles-for-tele/" target="_blank" rel="noreferrer">compensated saddles</a>" for the 3-saddle telecasters. This  will help with standard tunings. But if you plan to use tunings outside of standard, you may want to use a Telecaster style bridge with 6 separate saddles. This will allow independent adjustments on each string for intonation and string height.
              </p>
              <FormWrapper
                modal
                buttonProps={{
                  content: "Reach Out. Let's Talk!",
                  size: "tiny",
                  icon: "chat",
                }}
              >
              <RequestMoreInfo />
            </FormWrapper>
          </div>
          }
          textRight={
            <div>
              <img src="/images/guitar-setup-intonation.jpg" alt="Intonation Guitar Setup" />
          </div>
          }
        />
      </PageWrapper>
      <TextBlock
        textPadded
        textLeft={
          <div>
            <img src="/images/guitar-setup-bridge-choice.jpg" alt="Guitar Setup" />
          </div>
        }
        textRight={
          <div>
            <h2>Bridge Choice and Placement</h2>
            <p>
              Certain <a href="/guitar-bridges/">guitar bridges</a> have limitations with intonation when it comes to dropped tunings due to their design. For instance, a Stratocaster bridge goes through the <a href="/guitar-body/">body of the guitar</a>. It has a limited range for intonation with dropped tunings because the saddles can only travel so far back. One of the benefits of your custom guitar is we can set your bridge a small amount further back than standard. This will provide a lower range for proper intonation. Yet, it gives the saddles plenty of range for standard tuning as well.
            </p>
            <h2>Action</h2>
            <p>
              The style of music you play often dictates the action of your guitar setup. For instance, many slide guitar players like a higher than usual action. Very fast players often like their action very low.
            </p>
          </div>
        }
      />
      <div className="section textured">
        <TextBlock
          textPadded
          textLeft={
            <div>
              <h2>Frets and The Fretboard</h2>
              <p>
                The choice of frets and the <a href="/custom-guitar-necks/">radius of the fretboard</a> can have a major impact on your custom guitar's feel and playability. Faster players often like a flatter fretboard radius. Guitarists used to vintage-style necks will prefer a radius that is more rounded. Then there is the gauge of the fret wire and the height your frets are set. I work with you to make sure we select whatever will work best for your style and yield the ideal guitar for you.
              </p>
            </div>
          }
          textRight={
            <div>
              <img src="/images/guitar-setup-fret-wire.jpg" alt="Guitar Setup Fret Wire" />
            </div>
          }
        />
      </div>
      <TextBlock
        textPadded
        textLeft={
          <div>
            <img src="/images/guitar-setup-neck-adjustments.jpg" alt="Guitar Setup Truss Rod Adjustments" />
          </div>
        }
        textRight={
          <div>
            <h2>Neck Relief Adjustment</h2>
            <p>
              Truss rods affect your custom guitar's relief, action, and intonation. The truss rod is a long metal rod that runs the entire length of the guitar's neck. A guitar tech can adjust the bar to make the neck of the guitar resist the tension created by the strings. Truss rods are usually made of steel, but some truss rods use other materials such as graphite. The truss rod can compensate for string tension, seasonal weather changes, fret wear, and different playing styles. When tuning a guitar, the strings put a lot of tension on the neck. Thicker gauge strings create even more tension. If the guitar did not have a truss rod, the guitar's neck would sag over time until the action became unplayable. 
            </p>
            <p>
              I install the truss rod in a channel in the <a href="/custom-guitar-necks/">neck of the guitar</a>, under the fretboard, during the construction of the neck. The truss rod gives the neck considerable stability and control. There are two types of truss rods in guitars: single action and double action. 
            </p>
            <p>
              Single action rods are threaded on one side and the other side is fixed. The limitation of these truss rods is that they are only used to create rear arches. You can adjust the single-action lever back and forth to adjust the neck, but if you try to arc forward you'll hit a limit.
            </p>
            <p>
              Double-action truss rods are threaded on both sides and have a fixed shank that runs the entire length. Double-acting truss rods allow adjustments to create a back or front bow. I install double-action truss rods into all my custom necks. They will provide the most control for the life of your custom guitar.
            </p>
          </div>
        }
      />
      <PageWrapper color="grey">
        <TextBlock
          textPadded
          textLeft={
            <div>
              <h2>Nut Adjustments</h2>
              <p>
                There are four possible adjustments to your guitar's nut: the nut height, nut slot depths, slot widths, and slot angles. I determine the slot angle by the style of the headstock on your guitar. This prevents string binding in the slot channel. The choice of nut material can also change the tone of your guitar. Often I will use real bone nuts. But some people prefer nylon nuts that were common on classic Gibson-style guitars. This is another one of the choices I'll help you make as we design an electric guitar made for you.
              </p>
          </div>
          }
          textRight={
            <div>
              <img src="/images/guitar-setup-nut.jpg" alt="Guitar Setup Nut" />
          </div>
          }
        />
      </PageWrapper>
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h4>Get in touch. Let's talk about building your dream guitar!</h4>
            <RequestMoreInfo />
          </div>
        }
      />
    </PageLayout>
  )
}

export default GuitarSetup
